<template>
    <div class="dropbox-poopup">
        <b-form-file
            multiple
            v-model="Data.images"
            class="input-file"
            accept=".jpg, .png, .gif, .webp,"
            placeholder="Choose file"
            @input="onFileInputChange()"
        />
        <draggable
            :list="imageData"
            tag="ul"
            class="list-group list-group-flush cursor-move"
        >
            <b-list-group-item
                v-for="(listItem, index) in imageData"
                :key="index"
                tag="li"
            >
                <div class="d-flex">
                    <div class="ml-25">
                        <div class="remove-image" @click="removeImage(index)">
                            <feather-icon icon="XCircleIcon" size="20" vari />
                        </div>
                        <b-img :src="listItem"
                            fluid
                            class="rounded mr-2 mb-1 mb-md-0" 
                        />
                        <div v-if="listItem.loading" class="overlay">
                            <b-spinner
                                style="width: 3rem; height: 3rem;"
                                class="mr-1"
                                label="Large Spinner"
                            />
                        </div>
                        <div v-if="listItem.err" class="overlay">
                            <feather-icon icon="AlertCircleIcon"
                                size="50"
                                class="mr-1"
                            />
                        </div>
                    </div>
                </div>
            </b-list-group-item>
        </draggable>
        <p>
            Drag your file(s) here to begin<br> or click to browse
        </p>
    </div>
</template>

<script>
import { $themeConfig } from '@themeConfig'
import { BFormFile, BCardText, BListGroupItem, BImg, BSpinner } from 'bootstrap-vue'
import draggable from 'vuedraggable'
import store from '@/store'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, watch, computed, onUnmounted, onMounted } from '@vue/composition-api'
import furnitureStoreModule from '../furnitureStoreModule'

export default {
    components:{
        BFormFile,
        BCardText,
        BListGroupItem,
        BImg,
        draggable,
        BSpinner
    },

    props: {
        roomIndex: {
            type: Number,
            required: true,
        },

        furnitureIndex: {
            type: Number,
            required: true,
        },

        roomData: {
            type: Array,
            required: true,
        },

        imageData: {
            type: Array,
            required: true,
        },
         
        isDefect: {
            type: Boolean,
            required: true
        }
    },

    data(){
        return {
            images: [],
        }
    },
    computed: {
      
    },
    setup(props){
        const FURNITURE_APP_STORE_MODULE_NAME = "app-furniture";

        if (!store.hasModule(FURNITURE_APP_STORE_MODULE_NAME)) {
            store.registerModule(FURNITURE_APP_STORE_MODULE_NAME, furnitureStoreModule);
        }

        onUnmounted(() => {
            if (store.hasModule(FURNITURE_APP_STORE_MODULE_NAME)) {
                store.unregisterModule(FURNITURE_APP_STORE_MODULE_NAME);
            }
        });

        const itemFiles = ref([]);
        const blankData = {
            images:[]
        }

        const Data = ref(blankData);
        const listfiles = ref([]);

        const onFileInputChange = async () => {
            let images = Data.value.images;
            const resizedImages = [];

            const resizeImage = (file) =>
                new Promise((resolve, reject) => {
                    const reader = new FileReader();

                    reader.onload = (event) => {
                        const img = new Image();
                        img.onload = () => {
                            const maxWidth = 1040;
                            const maxHeight = 1040;

                            let width = img.width;
                            let height = img.height;

                            if (width > height && width > maxWidth) {
                                height *= maxWidth / width;
                                width = maxWidth;
                            } else if (height > width && height > maxHeight) {
                                width *= maxHeight / height;
                                height = maxHeight;
                            }

                            const canvas = document.createElement("canvas");
                            canvas.width = width;
                            canvas.height = height;
                            const ctx = canvas.getContext("2d");
                            ctx.drawImage(img, 0, 0, width, height);

                            canvas.toBlob(
                                (blob) => {
                                    resolve(blob);
                                },
                                file.type || "image/jpeg",
                                0.9
                            );
                        };

                        img.onerror = () => reject("Failed to load image.");
                        img.src = event.target.result;
                    };

                    reader.onerror = () => reject("Failed to read file.");
                    reader.readAsDataURL(file);
                });

                for (const file of images) {
                    try {
                        const resizedImage = await resizeImage(file);
                        resizedImages.push(resizedImage);
                    } catch (error) {
                        console.error("Error resizing image:", error);
                    }
                }

                const formData = new FormData();

                if (resizedImages.length === 1) {
                    formData.append("image", resizedImages[0]);
                } else if (resizedImages.length > 1) {
                    resizedImages.forEach((file, index) => {
                        formData.append(`images`, file);
                    });
                }

                store
                    .dispatch("app-furniture/uplodeImageData", { formData })
                    .then((res) => {
                        const imagePath = res.data.data;
                        itemFiles.value = imagePath;

                        const targetRoom = props.roomData.find(
                            (room) => room.roomIndex == props.roomIndex
                        );

                        if (props.isDefect) {
                            if (targetRoom) {
                                const targetDefect = targetRoom.defects.find(
                                    (defect) => defect.itemIndex == props.furnitureIndex
                                );

                                if (targetDefect) {
                                    if (Array.isArray(targetDefect.images) && targetDefect.images.length) {
                                        targetDefect.images.push(...imagePath);
                                    } else {
                                        targetDefect.images = [...imagePath];
                                    }
                                }
                            }
                        } else {
                            if (targetRoom) {
                                const targetFurniture = targetRoom.furnitures.find(
                                    (furniture) => furniture.itemIndex == props.furnitureIndex
                                );

                                if (targetFurniture) {
                                    if (
                                        Array.isArray(targetFurniture.images) &&
                                        targetFurniture.images.length
                                    ) {
                                        targetFurniture.images.push(...imagePath);
                                    } else {
                                        targetFurniture.images = [...imagePath];
                                    }
                                }
                            }
                        }
                    })
                    .catch((err) => {
                        console.error("Error uploading images", err);
                    });
        };

        return {
            onFileInputChange,
            listfiles,
            Data,
            itemFiles,
        }
    },
    methods:{
        removeImage(index){

            const imageIndex = index
            const targetRoom = this.roomData.find((room) => room.roomIndex == this.roomIndex)

            if(this.isDefect){
                if (targetRoom) {
                    const targetDecfect = targetRoom.defects.find(
                        (defect) => defect.itemIndex == this.furnitureIndex
                    );

                    if (targetDecfect && targetDecfect.images && targetDecfect.images.length > 0) {
                        const removeImage = targetDecfect.images.splice(imageIndex, 1)

                        store.dispatch('app-furniture/removeImageData', { removeImage })
                            .then((response) => {
                                console.log('res', response)
                            })
                            .catch((err) => {
                                console.error('Error remove images', err);
                            });
                    }
                }
            } else {
                if (targetRoom) {
                    const targetFurniture = targetRoom.furnitures.find(
                        (furniture) => furniture.itemIndex == this.furnitureIndex
                    );

                    if (targetFurniture && targetFurniture.images && targetFurniture.images.length > 0) {
                        const removeImage = targetFurniture.images.splice(imageIndex, 1)

                        store.dispatch('app-furniture/removeImageData', { removeImage })
                            .then((response) => {
                                console.log('res', response)
                            })
                            .catch((err) => {
                                console.error('Error remove images', err);
                            });
                    }
                }
            }
        }
    }
}
</script>

<style lang="scss">
  
    .custom-file-label{
        cursor: pointer;
    }

</style>
