<template>
    <div>
        <furniture-form
            :is-furniture-modal-active.sync="isFurnitureModalActive"
            :furnitures="furnitures"
            :defects="defects"
            :roomData="roomData"
            :keyRoom="keyRoom"
            :isDefect="isDefect"
            @getData="addFurnitureData"
        />
        <repeater-furniture-form
            :itemData="furnitureData"
            :keyRoom="keyRoom"
            :roomData="roomData"
            :isDefect="false"
        />
        <b-row>
            <b-col md="12" class="text-right">
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="addFurniture"
                >
                    <feather-icon icon="PlusIcon" class="mr-25" />
                    <span>{{ keyRoom == 0? 'Item': 'Furniture' }}</span>
                </b-button>
            </b-col>
        </b-row>
        <div v-if="keyRoom != 0 && furnitureData.length">
            <hr/>
            <b-row>
                <b-col md="12">
                    <h3>Defect</h3>
                </b-col>
            </b-row>
            <repeater-furniture-form
                :itemData="defectData"
                :keyRoom="keyRoom"
                :roomData="roomData"
                :isDefect="true"
            />
            <b-row>
                <b-col md="12" class="text-right">
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        @click="addDefect"
                    >
                        <feather-icon icon="PlusIcon" class="mr-25" />
                        <span>Defect</span>
                    </b-button>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
    import {
        BRow, BCol, BButton, BCard, BFormFile, BFormInput, BFormGroup, BFormSpinbutton, BFormTextarea, BCollapse,
        BMedia, BMediaAside, BImg,
    } from 'bootstrap-vue';
    import { ref, onMounted, computed, watch, onUnmounted, reactive } from '@vue/composition-api';
    import store from '@/store';
    import draggable from "vuedraggable";
    import Ripple from "vue-ripple-directive";
    import furnitureForm from './furnitureForm.vue';
    import RepeaterFurnitureForm from './RepeaterFurnitureForm.vue';
    import BCardActions from '../../../@core/components/b-card-actions/BCardActions.vue'
    import UploadForm from '../inventory-form/UploadForm.vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'

  export default {
    components: {
        BRow,
        BCol,
        BButton,
        BCard,
        furnitureForm,
        draggable,
        RepeaterFurnitureForm,
        BFormFile,
        BCardActions,
        UploadForm,
        BFormInput,
        BFormGroup,
        ValidationProvider,
        ValidationObserver,
        BFormSpinbutton,
        BFormTextarea,
        BCollapse,
        BMedia,
        BMediaAside,
        BImg,
    },

    directives: {
        Ripple,
    },

    props: {
        roomData: {
            type: Array,
            required: true
        },

        keyRoom: {
            type: Number,
            required: true
        },

        furnitureData: {
            type: Array,
            required: true
        },

        defectData: {
            type: Array,
            required: false
        }
    },

    data() {
        return {
            isCardVisible: true,
        };
    },

    setup(props, { emit }){
        const isFurnitureModalActive = ref(false)
        const roomType = ref({})
        const roomItems = ref({})
        const blankData = ref({
            images: [],
            title: '',
            description: '',
            arrival: '',
            departure: '',
            quantity: 1
        })
        const Data = ref(blankData)
        const files = ref([])
        const visibleSections = ref({});
        const isOpen = ref({})
        const tabIndex = ref(1);
        const reviewData = ref({})
        const furnitures = ref([])
        const defects = ref([])
        const isDefect = ref(false)

        const getRooms = async () => {
            props.furnitureData.forEach((_, index) => {
                visibleSections.value[index] = true;
                isOpen.value = visibleSections.value[index]
            });
        }

        onMounted(() => {
            getRooms()
        })

        const addFurniture = () => {
            isDefect.value = false
            furnitures.value = props.furnitureData
            isFurnitureModalActive.value = true;
        }

        const addDefect = () => {
            isDefect.value = true
            defects.value = props.defectData
            isFurnitureModalActive.value = true;
        }

        const deleteItem = (index) => {
            props.furnitureData.splice(index,1);
        }

        const addFurnitureData = (data) => {
            emit('getRoomData', data)
        }

        return{
            isFurnitureModalActive,
            roomType,
            getRooms,
            addFurniture,
            deleteItem,
            Data,
            files,
            isOpen,
            visibleSections,
            tabIndex,
            reviewData,
            furnitures,
            addFurnitureData,
            addDefect,
            defects,
            isDefect,
        }
    }
  }
</script>