<template>
<div>
  <b-card v-for="(item, index) in itemData" :key="index">
    <draggable :options="{ handle: '.handle' }">
      <div :class="{'border p-2': isCardVisible}">
        <section>
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
              <feather-icon
                icon="MenuIcon"
                size="18"
                class="cursor-pointer handle mr-2"
              />
                <b-media-aside v-if="keyRoom != 0">
                  <b-img
                    ref="refPreviewEl"
                    :src="item.images.length?item.images[0]:require('@/assets/images/logo/logo.jpeg')"
                    height="75"
                    width="75"
                    class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                <span v-if="Array.isArray(item.title)">{{ item.title.join(', ') }}</span>
            </div>
            <div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                class="mr-2"
                :class="isOpen ? null : 'collapsed'"
                :aria-expanded="isOpen ? 'true' : 'false'"
                aria-controls="'collapse-' + index"
                @click="toggleVisibility(index)"
              >
                {{ visibleSections[index] ? 'Hide' : 'Show' }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                @click="deleteItem(index)"
              >
                <feather-icon icon="XIcon" />
              </b-button>
            </div>
          </div>
          <b-collapse id="'collapse-' + index" v-model="visibleSections[index]" class="mt-2">
            <br/>
            <upload-form 
              v-if="tabIndex == 1 && keyRoom != 0"
              :roomIndex="keyRoom"
              :furnitureIndex="index"
              :roomData="roomData"
              :imageData="item.images"
              :isDefect="isDefect"
            />
            <br/>
            <b-row>
              <b-col md="12" class="mb-2">
                <div class="custom-spacing">
                  <label for="sb-inline">Quantity</label>
                  <b-form-spinbutton 
                    id="sb-inline" 
                    v-model="item.quantity" 
                    inline
                    min="0"
                    @input="onInputQuantity(index, item.quantity)"
                  ></b-form-spinbutton>
                </div>
              </b-col>
              <b-col md="12">
                <b-form-group>
                  <label>Description</label>
                  <validation-provider
                    #default="{ errors }"
                    name="description"
                  >
                    <b-form-textarea 
                      class="form-control" 
                      v-model="item.description" 
                      rows="3"
                      @input="onInputDescription(index, item.description)"
                    ></b-form-textarea>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12" class="text-right">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="addFurniture"
                >
                  <span>Save</span>
                </b-button>
              </b-col>
            </b-row>
          </b-collapse>
        </section>
      </div>
    </draggable>
  </b-card>
</div>
</template>

<script>
import {
  BRow, BCol, BButton, BCard, BFormFile, BFormInput, BFormGroup, BFormSpinbutton, BFormTextarea, BCollapse,
  BMediaAside, BImg,
} from 'bootstrap-vue';
import { ref } from '@vue/composition-api';
import store from '@/store';
import draggable from "vuedraggable";
import Ripple from "vue-ripple-directive";
import UploadForm from '../inventory-form/UploadForm.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    draggable,
    BFormFile,
    UploadForm,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BFormSpinbutton,
    BFormTextarea,
    BCollapse,
    BMediaAside, 
    BImg
  },

  directives: {
    Ripple,
  },

  props: {
    roomData: {
      type: Array,
      required: true
    },

    itemData: {
      type: Array,
      required: false
    },

    keyRoom: {
      type: Number,
      required: true
    },

    isDefect: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      isCardVisible: true,
    };
  },

  setup(props, { emit }){
    const isOpen = ref({})
    const visibleSections = ref({});
    const tabIndex = ref(1);
    const isFurnitureModalActive = ref(false)

    const addFurniture = () => {
      isFurnitureModalActive.value = true;
    }

    const toggleVisibility = (index) =>  {
      isOpen.value = {}

      if(visibleSections.value[index]){
          visibleSections.value[index] = false
      } else {
          visibleSections.value[index] = true
      }
      
      isOpen.value = visibleSections.value[index]
    }

    const deleteItem = (index) => {
      props.itemData.splice(index,1);
    }

    const onInputDescription = (index, data) => {
      if(!props.isDefect){
        props.roomData[props.keyRoom].furnitures[index].description = data
      } else {
        props.roomData[props.keyRoom].defects[index].description = data
      }
    }

    const onInputQuantity = (index, data) => {
      if(!props.isDefect){
        props.roomData[props.keyRoom].furnitures[index].quantity = data
      } else {
        props.roomData[props.keyRoom].defects[index].quantity = data
      }
    }

    return {
      isOpen,
      visibleSections,
      tabIndex,
      addFurniture,
      isFurnitureModalActive,
      toggleVisibility,
      deleteItem,
      onInputDescription,
      onInputQuantity,
    }
  }
}
</script>

<style scoped>
  .custom-spacing label {
    margin-right: 15px;
  }
</style>