<template>
    <div class="dropbox-poopup">
        <b-form-file
            v-model="Data.images"
            class="input-file"
            accept=".jpg, .png, .gif, .webp,"
            placeholder="Choose file"
            @input="onFileInputChange()"
        />
        <draggable
            tag="ul"
            class="list-group list-group-flush cursor-move"
        >
            <b-list-group-item
                tag="li"
            >
                <div class="d-flex">
                    <div class="ml-25">
                        <div class="remove-image" @click="removeImage()">
                            <feather-icon icon="XCircleIcon" size="20" vari v-if="roomData[0].image"/>
                        </div>
                        <b-img 
                            :src="roomData[0].image"
                            fluid
                            class="rounded mr-2 mb-1 mb-md-0" 
                        />
                    </div>
                </div>
            </b-list-group-item>
        </draggable>
        <p>
            Drag your file here to begin<br> or click to browse
        </p>
    </div>
</template>

<script>
import { $themeConfig } from '@themeConfig'
import { BFormFile, BCardText, BListGroupItem, BImg, BSpinner } from 'bootstrap-vue'
import draggable from 'vuedraggable'
import store from '@/store'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, watch, computed, onUnmounted, onMounted } from '@vue/composition-api'
import furnitureStoreModule from '../furnitureStoreModule'

export default {
    components:{
        BFormFile,
        BCardText,
        BListGroupItem,
        BImg,
        draggable,
        BSpinner
    },

    props: {
        roomIndex: {
            type: Number,
            required: false,
        },

        furnitureIndex: {
            type: Number,
            required: false,
        },

        roomData: {
            type: Array,
            required: true,
        },

        imageData: {
            type: Array,
            required: false,
        },
         
        isDefect: {
            type: Boolean,
            required: false
        }
    },

    data(){
        return {
            images: [],
        }
    },
    computed: {
      
    },
    setup(props){
        const FURNITURE_APP_STORE_MODULE_NAME = "app-furniture";

        if (!store.hasModule(FURNITURE_APP_STORE_MODULE_NAME)) {
            store.registerModule(FURNITURE_APP_STORE_MODULE_NAME, furnitureStoreModule);
        }

        onUnmounted(() => {
            if (store.hasModule(FURNITURE_APP_STORE_MODULE_NAME)) {
                store.unregisterModule(FURNITURE_APP_STORE_MODULE_NAME);
            }
        });

        const itemFiles = ref([]);
        const blankData = {
            images:[]
        }

        const Data = ref(blankData);
        const listfiles = ref([]);

        const onFileInputChange = async () => {
            let images = Data.value.images;
            const formData = new FormData();

            formData.append("image", images);

            store
                .dispatch("app-furniture/uplodeImageData", { formData })
                .then((res) => {
                    const imagePath = res.data.data[0];
                    itemFiles.value = imagePath;

                    const targetRoom = props.roomData.map(
                        (room) => room.image = imagePath
                    );
                })
                .catch((err) => {
                    console.error("Error uploading images", err);
                });
        };

        return {
            onFileInputChange,
            listfiles,
            Data,
            itemFiles,
        }
    },
    methods:{
        removeImage(){
            this.roomData[0].image = ''
        }
    }
}
</script>

<style lang="scss">
  
    .custom-file-label{
        cursor: pointer;
    }

</style>
