<template>
<div>
    <room-form
        :is-room-modal-active.sync="isRoomModalActive"
        @room-type="getRoomType"
    />
    <div>
        <b-card-actions noActions>
            <div class="d-flex justify-content-between align-items-right">
                <h4 class="mb-0">Unite</h4>
                <b-button
                    v-if="inventoryId"
                    class="ms-auto"
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-primary"
                    @click="generateReport"
                >
                    Download PDF
                </b-button>
            </div>
            <b-row>
                <b-col md="4">
                    <b-form-group>
                        <label>Property Pin</label>
                        <div>
                            <b>{{ selectedPropertyData != null? selectedPropertyData.place_name: '' }}</b>
                        </div>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group>
                        <label>Code</label>
                        <div>
                            <b>{{ selectedPropertyData != null? selectedPropertyData.code: '' }}</b>
                        </div>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="4">
                    <b-form-group>
                        <label>Address No.</label>
                        <div>
                            <b>{{ selectedPropertyData != null? selectedPropertyData.address_no: '' }}</b>
                        </div>
                    </b-form-group>
                </b-col>
                <b-col md="4">
                    <b-form-group>
                        <label>Unit</label>
                        <div>
                            <b>{{ selectedPropertyData != null? selectedPropertyData.units: '' }}</b>
                        </div>
                    </b-form-group>
                </b-col>
                <b-col md="4">
                    <b-form-group>
                        <label>Floor</label>
                        <div>
                            <b>{{ selectedPropertyData != null? selectedPropertyData.floors: '' }}</b>
                        </div>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="6">
                    <b-form-group>
                        <label>Lessor</label>
                        <validation-provider
                            #default="{ errors }"
                            name="lessor"
                        >
                            <b-form-input
                                v-model="propertyInputData.lessor"
                                placeholder="Lessor"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group>
                        <label>Lessee</label>
                        <validation-provider
                            #default="{ errors }"
                            name="lessee"
                        >
                            <b-form-input
                                v-model="propertyInputData.lessee"
                                placeholder="lessee"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="4">
                    <b-form-group>
                        <label>Lease Start</label>
                        <validation-provider
                            #default="{ errors }"
                            name="leaseStart"
                        >
                            <b-form-datepicker 
                                v-model="propertyInputData.leaseStart" 
                                id="lease-start" 
                                class="mb-2"
                                locale="en-GB" 
                                :date-format-options="{ day: '2-digit', month: '2-digit', year: 'numeric' }"
                            >
                            </b-form-datepicker>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col md="4">
                    <b-form-group>
                        <label>Lease Expire</label>
                        <validation-provider
                            #default="{ errors }"
                            name="leaseExpire"
                        >
                            <b-form-datepicker 
                                v-model="propertyInputData.leaseExpire" 
                                id="lease-expire" 
                                class="mb-2"
                                locale="en-GB" 
                                :date-format-options="{ day: '2-digit', month: '2-digit', year: 'numeric' }"
                            >
                            </b-form-datepicker>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col md="4">
                    <b-form-group>
                        <label>Date of record</label>
                        <validation-provider
                            #default="{ errors }"
                            name="dateOfRecord"
                        >
                            <b-form-datepicker 
                                v-model="propertyInputData.dateOfRecord" 
                                id="date-of-record" 
                                class="mb-2"
                                locale="en-GB" 
                                :date-format-options="{ day: '2-digit', month: '2-digit', year: 'numeric' }"
                            >
                            </b-form-datepicker>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
            </b-row>
            <hr/>
            <b-row>
                <b-col>
                    <label>Electricity Meter</label>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="6" align-self="stretch" class="custom-col">
                    <b-form-group>
                        <div>
                            <br/>
                            <b-media
                                no-body
                                vertical-align="center"
                                class="flex-column flex-md-row"
                            >
                                <b-media-aside>
                                    <b-img
                                        ref="refPreviewElectricityStart"
                                        :src="propertyInputData.electricity_arrival_preview? propertyInputData.electricity_arrival_preview: require('@/assets/images/logo/logo-electricity.png')"
                                        height="75"
                                        width="75"
                                        class="rounded mr-2 mb-1 mb-md-0"
                                    />
                                </b-media-aside>
                                <b-media-body>
                                    <small class="text-muted">
                                        Recommend image resolution 75x75.
                                    </small>
                                    <b-card-text class="my-50"> </b-card-text>
                                    <div class="d-inline-block">
                                    <b-form-file
                                        ref="refInputElectricityStart"
                                        v-model="propertyInputData.electricityArrivalFile"
                                        accept=".jpg, .png, .gif,.webp,"
                                        placeholder="Choose file"
                                        @input="inputImageRenderer"
                                    />
                                    </div>
                                    <b-card-text class="my-50">
                                        <b-button
                                            v-if="propertyInputData.electricity_arrival_preview"
                                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                            variant="outline-primary"
                                            @click="revomeElectricityArrivalImage"
                                        >
                                          Remove Image
                                        </b-button>
                                    </b-card-text>
                                </b-media-body>
                            </b-media>
                        </div>
                    </b-form-group>
                    <b-form-group>
                        <label>On Arrival</label>
                        <validation-provider
                            #default="{ errors }"
                            name="electricity_arrival"
                        >
                            <b-form-input
                                v-model="propertyInputData.electricity_arrival"
                                placeholder="arrival"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col md="6" align-self="stretch" class="custom-col">
                    <b-form-group>
                        <div>
                            <br/>
                            <b-media
                                no-body
                                vertical-align="center"
                                class="flex-column flex-md-row"
                            >
                                <b-media-aside>
                                    <b-img
                                        ref="refPreviewElectricityEnd"
                                        :src="propertyInputData.electricity_departure_preview? propertyInputData.electricity_departure_preview:require('@/assets/images/logo/logo-electricity.png')"
                                        height="75"
                                        width="75"
                                        class="rounded mr-2 mb-1 mb-md-0"
                                    />
                                </b-media-aside>
                                <b-media-body>
                                    <small class="text-muted">
                                        Recommend image resolution 75x75.
                                    </small>
                                    <b-card-text class="my-50"> </b-card-text>
                                    <div class="d-inline-block">
                                    <b-form-file
                                        ref="refInputElectricityEnd"
                                        v-model="propertyInputData.electricityDepartureFile"
                                        accept=".jpg, .png, .gif,.webp,"
                                        placeholder="Choose file"
                                        @input="inputElecEndImageRenderer"
                                    />
                                    </div>
                                    <b-card-text class="my-50">
                                        <b-button
                                            v-if="propertyInputData.electricity_departure_preview"
                                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                            variant="outline-primary"
                                            @click="revomeElectricityDepartureImage"
                                        >
                                          Remove Image
                                        </b-button>
                                    </b-card-text>
                                </b-media-body>
                            </b-media>
                        </div>
                    </b-form-group>
                    <b-form-group>
                        <label>On Departure</label>
                        <validation-provider
                            #default="{ errors }"
                            name="electricity_departure"
                        >
                            <b-form-input
                                v-model="propertyInputData.electricity_departure"
                                placeholder="departure"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
            </b-row>
            <hr/>
            <b-row>
                <b-col>
                    <label>Water Meter</label>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="6" align-self="stretch" class="custom-col">
                    <b-form-group>
                        <div>
                            <br/>
                            <b-media
                              no-body
                              vertical-align="center"
                              class="flex-column flex-md-row"
                            >
                              <b-media-aside>
                                <b-img
                                  ref="refPreviewWaterStart"
                                  :src="propertyInputData.water_arrival_preview?propertyInputData.water_arrival_preview:require('@/assets/images/logo/logo-water.jpg')"
                                  height="75"
                                  width="75"
                                  class="rounded mr-2 mb-1 mb-md-0"
                                />
                              </b-media-aside>
                              <b-media-body>
                                <small class="text-muted"
                                  >Recommend image resolution 75x75.</small
                                >
                                <b-card-text class="my-50"> </b-card-text>
                                <div class="d-inline-block">
                                  <b-form-file
                                    ref="refInputWaterStart"
                                    v-model="propertyInputData.waterStartFile"
                                    accept=".jpg, .png, .gif,.webp,"
                                    placeholder="Choose file"
                                    @input="inputWaterStartImageRenderer"
                                  />
                                </div>
                                <b-card-text class="my-50">
                                    <b-button
                                        v-if="propertyInputData.water_arrival_preview"
                                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                        variant="outline-primary"
                                        @click="revomeWaterArrivalImage"
                                    >
                                      Remove Image
                                    </b-button>
                                </b-card-text>
                              </b-media-body>
                            </b-media>
                        </div>
                    </b-form-group>
                    <b-form-group>
                        <label>On Arrival</label>
                        <validation-provider
                            #default="{ errors }"
                            name="water_arrival"
                        >
                            <b-form-input
                                v-model="propertyInputData.water_arrival"
                                placeholder="arrival"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <b-col md="6" align-self="stretch" class="custom-col">
                    <b-form-group>
                        <div>
                            <br/>
                            <b-media
                              no-body
                              vertical-align="center"
                              class="flex-column flex-md-row"
                            >
                              <b-media-aside>
                                <b-img
                                  ref="refPreviewWaterEnd"
                                  :src="propertyInputData.water_departure_preview?propertyInputData.water_departure_preview:require('@/assets/images/logo/logo-water.jpg')"
                                  height="75"
                                  width="75"
                                  class="rounded mr-2 mb-1 mb-md-0"
                                />
                              </b-media-aside>
                              <b-media-body>
                                <small class="text-muted"
                                  >Recommend image resolution 75x75.</small
                                >
                                <b-card-text class="my-50"> </b-card-text>
                                <div class="d-inline-block">
                                  <b-form-file
                                    ref="refInputWaterEnd"
                                    v-model="propertyInputData.waterDepartureFile"
                                    accept=".jpg, .png, .gif,.webp,"
                                    placeholder="Choose file"
                                    @input="inputWaterEndImageRenderer"
                                  />
                                </div>
                                <b-card-text class="my-50">
                                    <b-button
                                        v-if="propertyInputData.water_departure_preview"
                                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                        variant="outline-primary"
                                        @click="revomeWaterDepartureImage"
                                    >
                                      Remove Image
                                    </b-button>
                                </b-card-text>
                              </b-media-body>
                            </b-media>
                        </div>
                    </b-form-group>
                    <b-form-group>
                        <label>On Departure</label>
                        <validation-provider
                            #default="{ errors }"
                            name="water_departure"
                        >
                            <b-form-input
                                v-model="propertyInputData.water_departure"
                                placeholder="departure"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="12" class="text-right">
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        @click="addRoom"
                    >
                        <feather-icon icon="PlusIcon" class="mr-25" />
                        <span>Room</span>
                    </b-button>
                </b-col>
                <b-col md="2"></b-col>
            </b-row>
        </b-card-actions>
        <div v-if="propertyRooms.length">
            <div v-for="(item, index) in propertyRooms" :key="item.roomIndex">
                <b-card-actions action-collapse :action-close="item.title !== 'KEY HAND OVER'" :title="item.title" @close="removeItem(index)">
                    <upload-key-hand-form
                        v-if="item.title === 'KEY HAND OVER'"
                        :roomData="propertyRooms"
                    />
                    <repeater-room-form
                        :roomData="propertyRooms"
                        :keyRoom="item.roomIndex"
                        :furnitureData="item.furnitures"
                        :defectData="item.defects"
                        @getRoomData="getRoomData"
                    />
                </b-card-actions>
            </div>
        </div>
        <b-row>
            <b-col md="12" class="text-right">
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="onSavePropertyInventory"
                >
                    <span>Save</span>
                </b-button>
            </b-col>
        </b-row>
    </div>
</div>
</template>

<script>
  import {
    BFormInput, BFormGroup, BForm, BRow, BCol, BTable, BLink, BImg, BButton, BBadge,
    BContainer, BCard, BFormDatepicker, BMedia, BMediaAside, BMediaBody, BFormFile,
    BCardText,
  } from 'bootstrap-vue';
  import router from '@/router'
  import BCardActions from '../../../@core/components/b-card-actions/BCardActions.vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate';
  import { ref, onMounted, computed, watch, onUnmounted, reactive } from '@vue/composition-api';
  import store from '@/store';
  import ownerStoreModule from '../../users/owner/ownerStoreModule';
  import propertiesStoreModule from '../../properties/propertiesStoreModule';
  import furnitureStoreModule from '../furnitureStoreModule'
  import { filterTags, formatDate } from '@core/utils/filter'
  import { debounce } from "lodash";
  import draggable from "vuedraggable";
  import RoomForm from './RoomForm.vue';
  import Ripple from "vue-ripple-directive";
  import RepeaterRoomForm from './RepeaterRoomForm.vue';
  import RepeaterFurnitureForm from './RepeaterFurnitureForm.vue'
  import UploadForm from './UploadForm.vue';
  import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
  import InventoryListDocument from '../pdf-form/InventoryListDocument.vue'
  import VueHtml2pdf from 'vue-html2pdf'
  import { useToast } from 'vue-toastification/composition'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import UploadKeyHandForm from './UploadKeyHandForm.vue';
  
  export default {
    components: {
        BCardActions,
        BFormInput,
        BFormGroup,
        BForm,
        BRow,
        BCol,
        BTable,
        ValidationProvider,
        ValidationObserver,
        BLink,
        BImg,
        BButton,
        BBadge,
        draggable,
        RoomForm,
        BContainer,
        BCard,
        RepeaterRoomForm,
        RepeaterFurnitureForm,
        BFormDatepicker,
        UploadForm,
        BMedia,
        BMediaAside,
        BMediaBody,
        BFormFile,
        BCardText,
        InventoryListDocument,
        VueHtml2pdf,
        UploadKeyHandForm,
    },
    directives: {
        Ripple,
    },
    methods:{
        generateReport() {
            const routeData = this.$router.resolve({
                name: 'InventoryListDocument',
                query: { 
                    data: JSON.stringify(this.inventoryPrintData),  
                    inventory_id: this.$route.query.inventoryId
                },
            });
            window.open(routeData.href, '_blank');
        },

        makeToast(variant = null, title, message) {
            this.$bvToast.toast(message, {
                title: title,
                variant,
                solid: true,
            })
        },
    },
    mounted() {
        this.propertyId = this.$route.query?.propertyId

        if(this.$route.query.propertyId && this.$route.query.inventoryId){
            this.propertyId = this.$route.query.propertyId
            this.inventoryId = this.$route.query.inventoryId
            this.getData()
        }

        if(!this.$route.query.propertyId){
            this.closeSearchProperty = false
        }
    },
    setup() {
        const PROPERTY_APP_STORE_MODULE_NAME = "app-property";
        const FURNITURE_APP_STORE_MODULE_NAME = "app-furniture";

        if (!store.hasModule(PROPERTY_APP_STORE_MODULE_NAME)) {
            store.registerModule(PROPERTY_APP_STORE_MODULE_NAME, propertiesStoreModule);
        }

        if (!store.hasModule(FURNITURE_APP_STORE_MODULE_NAME)) {
            store.registerModule(FURNITURE_APP_STORE_MODULE_NAME, furnitureStoreModule);
        }

        onUnmounted(() => {
            if (store.hasModule(PROPERTY_APP_STORE_MODULE_NAME)) {
                store.unregisterModule(PROPERTY_APP_STORE_MODULE_NAME);
            }

            if (store.hasModule(FURNITURE_APP_STORE_MODULE_NAME)) {
                store.unregisterModule(FURNITURE_APP_STORE_MODULE_NAME);
            }
        });

        const tableColumns_property = [
            { key: "check_box", label: ""},
            { key: "image", label: "Image" },
            { key: "property", label: "Property" },
            { key: "owner", label: "Owner" },
            { key: "unit", label: "House No Units" },
            { key: "floors", label: "Floors" },
            { key: "type_name", label: "Type" },
            { key: "building", label: "Building" },
            { key: "bedroom", label: "BR" },
            { key: "sqm", label: "Sqm" },
        ];
  
        const currentPage = ref(1);
        const perPage = ref(10);
        const sortBy = ref('id');
        const isSortDirDesc = ref(true);
        const propertyData = ref([])
        const isRoomModalActive = ref(false)
        const roomType = ref({})
        const selectedPropertyId = ref(null)
        const closeSearchProperty = ref(false)
        const selectedPropertyData = ref(null)
        const keyHandover = ref({
            roomIndex: 0,
            title: 'KEY HAND OVER',
            image: '',
            furnitures: [],
        })
        const propertyRooms = ref([keyHandover.value])
        const userData = ref(JSON.parse(localStorage.getItem('userData')))
        const propertyId = ref(router.currentRoute.query.propertyId?? null)
        const inventoryId = ref(router.currentRoute.query.inventoryId?? null)
        const isOpen = ref(false)
        const inventoryPrintData = ref({})
        const isDowloadPdf = ref(false)
        const toast = useToast();
  
        const propertyFilters = ref({
            unit: '',
            propertyfilter: '',
            ownerfilter: '',
            floors: '',
            type_name: '',
            building: '',
            bedroom: '',
            sqm: '',
        });

        const propertyInputData = ref({
            electricity_arrival_preview: '',
            electricity_departure_preview: '',
            electricity_arrival: '',
            electricity_departure: '',
            water_arrival_preview: '',
            water_departure_preview: '',
            water_arrival: '',
            water_departure: '',
            leaseStart: '',
            leaseExpire: '',
            dateOfRecord: '',
            lessor: '',
            lessee: '',
            keyHand_preview: '',
        })

        const itemData = ref({
            roomIndex: null,
            title: '',
            furnitures: [],
            defects: [],
        })

        const refPreviewElectricityStart = ref(null);
        const refInputElectricityStart = ref(null);

        const { inputImageRenderer } = useInputImageRenderer(
            refInputElectricityStart,
            (base64) => {
                refPreviewElectricityStart.value.src = base64;
            }
        );

        const refPreviewElectricityEnd = ref(null);
        const refInputElectricityEnd = ref(null);

        const { inputElecEndImageRenderer } = useInputImageRenderer(
            refInputElectricityEnd,
            (base64) => {
                refPreviewElectricityEnd.value.src = base64;
            }
        );

        const refPreviewWaterStart = ref(null);
        const refInputWaterStart = ref(null);

        const { inputWaterStartImageRenderer } = useInputImageRenderer(
            refInputWaterStart,
            (base64) => {
                refPreviewWaterStart.value.src = base64;
            }
        );

        const refPreviewWaterEnd = ref(null);
        const refInputWaterEnd = ref(null);

        const { inputWaterEndImageRenderer } = useInputImageRenderer(
            refInputWaterEnd,
            (base64) => {
                refPreviewWaterEnd.value.src = base64;
            }
        );

        const refPreviewKeyHand = ref(null)
        const refInputKeyHand = ref(null);

        const { inputKeyHandImageRenderer } = useInputImageRenderer(
            refInputKeyHand,
            (base64) => {
                refPreviewKeyHand.value.src = base64;
            }
        );

        const revomeElectricityArrivalImage = () => {
            propertyInputData.value.electricity_arrival_preview = '';
        }

        const revomeElectricityDepartureImage = () => {
            propertyInputData.value.electricity_departure_preview = '';
        }

        const revomeWaterArrivalImage = () => {
            propertyInputData.value.water_arrival_preview = '';
        }

        const revomeWaterDepartureImage = () => {
            propertyInputData.value.water_departure_preview = '';
        }

        const revomeKeyHandImage = () => {
            propertyInputData.value.keyHand_preview = '';
        }
  
        async function fetchPropertieData (ctx, callback) {
            const filterPayload = {
                ...propertyFilters.value,
                offset: (currentPage.value - 1) * perPage.value,
                perpage: perPage.value,
                sort_by: sortBy.value,
                sort_desc: isSortDirDesc.value,
            };
    
            store
                .dispatch('app-property/fetchProperties', filterPayload)
                .then((response) => {
                    propertyData.value = response.data.data
                });
        };

        function fetchPropertyDetail (ctx, callback) {
            // getPropertyData
        }

        const debouncedFetchPropertieData = debounce(fetchPropertieData, 300);
  
        watch( propertyFilters, () => {
                debouncedFetchPropertieData();
            },
            { deep: true }
        );

        const getRooms = async (room_id) => {

        }

        const addRoom = () => {
            isRoomModalActive.value = true;
        };

        const onSavePropertyInventory = async () => {
            let formData = new FormData();

            const data = {
                id: inventoryId.value? inventoryId.value: '',
                propertyId: propertyId.value,
                user_id: userData.value.id
            }

            for(const [key, value] of Object.entries(data)) {
                formData.append(key, value);
            }

            for(const [key, value] of Object.entries(propertyInputData.value)) {
                formData.append(key, value);
            }

            formData.append('jsonData', JSON.stringify(propertyRooms.value))
            formData.append('imageElectricityStartFiles', refInputElectricityStart.value.files[0])
            formData.append('imageElectricityEndFiles', refInputElectricityEnd.value.files[0])
            formData.append('imageWaterStartFiles', refInputWaterStart.value.files[0])
            formData.append('imageWaterEndFiles', refInputWaterEnd.value.files[0])

            if(inventoryId.value){
                await store
                    .dispatch('app-furniture/updateInventoryListData', { formData: formData })
                    .then((response) => {
                        router.push('/inventory-lists', () => {
                            toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: `Done`,
                                    icon: 'CoffeeIcon',
                                    variant: 'success',
                                    text: `You have successfully updated.`,
                                },
                            })
                        });
                    })
                    .catch((error) => {
                        toast({
                            component: ToastificationContent,
                            props: {
                                title: "Error update inventory",
                                icon: 'AlertTriangleIcon',
                                variant: 'danger',
                            },
                        })
                    })
            } else {
                await store
                    .dispatch('app-furniture/addInventoryData', { formData: formData })
                    .then((response) => {
                        router.push('/inventory-lists', () => {
                            toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: `Done`,
                                    icon: 'CoffeeIcon',
                                    variant: 'success',
                                    text: `You have successfully added.`,
                                },
                            })
                        });
                    })
                    .catch((error) => {
                        toast({
                            component: ToastificationContent,
                            props: {
                                title: "Error add inventory",
                                icon: 'AlertTriangleIcon',
                                variant: 'danger',
                            },
                        })
                    })
            }
        }

        const getRoomType = (type) => {
            itemData.value.title = type
            itemData.value.roomIndex = propertyRooms.value.length
            itemData.value.furnitures = []
            itemData.value.defects = []
            propertyRooms.value.splice(propertyRooms.value.length, 0, { ...itemData.value });
        }

        const getRoomData = (data) => {
            console.log('getRoomData', data)
        }

        const removeItem = (index) => {
            propertyRooms.value.splice(index, 1);

            propertyRooms.value.forEach((room, idx) => {
                room.roomIndex = idx + 1;
            });
        }

        const getData = async () => {
            if(propertyId.value && inventoryId.value){
                closeSearchProperty.value = true
                
                await store
                    .dispatch('app-furniture/getPropertyData', propertyId.value)
                    .then((response) => {
                        selectedPropertyData.value = response.data.data
                    });

                if(inventoryId.value){
                    await store
                        .dispatch('app-furniture/getInventoryListData', {id: inventoryId.value})
                        .then((response) => {
                            const inventoryData = response.data.data[0]
                            propertyRooms.value = inventoryData.data
                            propertyInputData.value.leaseStart = inventoryData.start_date? inventoryData.start_date: ''
                            propertyInputData.value.leaseExpire = inventoryData.expire_date? inventoryData.expire_date: ''
                            propertyInputData.value.dateOfRecord = inventoryData.records_date? inventoryData.records_date: ''
                            propertyInputData.value.electricity_arrival = inventoryData.electricity_arrival
                            propertyInputData.value.electricity_arrival_preview = inventoryData.electricity_arrival_image
                            propertyInputData.value.electricity_departure = inventoryData.electricity_departure
                            propertyInputData.value.electricity_departure_preview = inventoryData.electricity_departure_image
                            propertyInputData.value.water_arrival = inventoryData.water_arrival
                            propertyInputData.value.water_arrival_preview = inventoryData.water_arrival_image
                            propertyInputData.value.water_departure = inventoryData.water_departure
                            propertyInputData.value.water_departure_preview = inventoryData.water_departure_image
                            propertyInputData.value.lessor = inventoryData.lessor
                            propertyInputData.value.lessee = inventoryData.lessee
                        });
                }
            } else {
                await store
                    .dispatch('app-furniture/getPropertyData', propertyId.value)
                    .then((response) => {
                        selectedPropertyData.value = response.data.data
                    });
            }
            
        }

        const inventoryListPrintData = () => {
            store
                .dispatch('app-furniture/getInventoryListPrintData', inventoryId.value)
                .then((response) => {
                    inventoryPrintData.value = response.data.data
                });
        }

        onMounted(() => {
            fetchPropertieData()
            getData()

            let queryURL = {}
            if(propertyId.value != null) queryURL.propertyId = propertyId.value
            if(inventoryId.value != null) queryURL.inventoryId = inventoryId.value

            const currentQuery = router.currentRoute.query;
            const newQueryString = new URLSearchParams(queryURL).toString();

            if (newQueryString !== new URLSearchParams(currentQuery).toString()) {
                router.replace({ query: queryURL });
            }

            if(router.currentRoute.params.inventoryId){
                inventoryListPrintData()
            }
        })
  
        return {
            tableColumns_property,
            fetchPropertieData,
            sortBy,
            isSortDirDesc,
            currentPage,
            perPage,
            propertyFilters,
            formatDate,
            filterTags,
            propertyData,
            isRoomModalActive,
            roomType,
            getRooms,
            addRoom,
            selectedPropertyId,
            onSavePropertyInventory,
            closeSearchProperty,
            selectedPropertyData,
            getRoomType,
            propertyRooms,
            getRoomData,
            removeItem,
            propertyInputData,
            inputImageRenderer,
            fetchPropertyDetail,
            inventoryId,
            isOpen,
            inventoryPrintData,
            isDowloadPdf,
            getData,
            refPreviewElectricityStart,
            refInputElectricityStart,
            revomeElectricityArrivalImage,
            inputElecEndImageRenderer,
            refPreviewElectricityEnd,
            refInputElectricityEnd,
            revomeElectricityDepartureImage,
            refPreviewWaterStart,
            refInputWaterStart,
            inputWaterStartImageRenderer,
            revomeWaterArrivalImage,
            inputWaterEndImageRenderer,
            revomeWaterDepartureImage,
            refPreviewWaterEnd,
            refInputWaterEnd,
            refPreviewKeyHand,
            refInputKeyHand,
            inputKeyHandImageRenderer,
            revomeKeyHandImage,
        };
    },
  };
  </script>

<style lang="scss" scoped>
    .per-page-selector {
        width: 90px;
    }

    .invoice-filter-select {
        min-width: 190px;

        ::v-deep .vs__selected-options {
            flex-wrap: nowrap;
        }

        ::v-deep .vs__selected {
            width: 100px;
        }
    }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';

.custom-col {
    height: 100%;
}
</style>