<template>
    <b-modal
        :visible="isFurnitureModalActive"
        size="l"
        hide-footer
        no-close-on-backdrop
        @change="(val) => $emit('update:is-furniture-modal-active', val)"
    >
        <template #modal-title>{{ keyRoom == 0? 'Add Item': 'Add Furniture' }}</template>
        <b-container class="bv-example-row">
            <b-row>
                <b-col cols="9">
                    <v-select
                        id="furniture"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        multiple
                        v-model="itemName"
                        :reduce="(option) => option.title"
                        :placeholder="keyRoom == 0? 'Add Item': 'Add Furniture'"
                        :options="furnitureItemData.map((g) => ({
                            title: g.name,
                            id: g.id
                        }))"
                        @input="onInput"
                        @search="onSearch"
                        taggable
                    />
                </b-col>
                <b-col cols="2">
                    <button type="button" class="btn btn-outline-success btn-xs" @click="addItem()">add</button>
                </b-col>
            </b-row>
        </b-container>
    </b-modal>
</template>
    
<script>
import {
    BCard, BCardText, BFormFile, BForm, BFormGroup, BFormInput, BRow, BCol, BButton,
    BImg, BModal, BFormInvalidFeedback, BTabs, BTab, BContainer, BDropdown, BDropdownItem,
    BIcon, BBadge,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import { ref, onMounted } from "@vue/composition-api";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import axios from "@axios";
import "quill/dist/quill.snow.css";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import RepeaterFurnitureForm from "./RepeaterFurnitureForm.vue";
    
export default {
    components: {
        BCard, BCardText, BFormFile, BTabs, BTab, BForm, BFormGroup, BFormInput, BRow, BCol,
        BButton, BImg, BFormInvalidFeedback, vSelect, BModal, ValidationProvider, ValidationObserver,
        BContainer, RepeaterFurnitureForm, BDropdown, BDropdownItem, BIcon, BBadge,
    },
    model: {
        prop: "isFurnitureModalActive",
        event: "update:is-furniture-modal-active",
    },
    props: {
        isFurnitureModalActive: {
            type: Boolean,
            required: true,
        },

        roomData: {
            type: Array,
            required: true
        },

        keyRoom: {
            type: Number,
            required: true
        },

        isDefect: {
            type: Boolean,
            required: true,
        },

        defects: {
            type: Array,
            required: false
        },

        furnitures: {
            type: Array,
            required: false
        }
    },
    directives: {
        Ripple,
    },
    setup(props, { emit }) {
        const roomType = ref('')
        const furnitureItemData = ref([])
        const itemName = ref([])
        const searchInput = ref('')
        const Data = ref({
            itemIndex: null,
            images: [],
            title: '',
            description: '',
            arrival: '',
            departure: '',
            quantity: 0
        })

        const addItem = () => {
            if(props.isDefect){
                Data.value.title = itemName.value
                Data.value.itemIndex = props.defects.length
                props.defects.splice(props.defects.length, 0, { ...Data.value });

                for(const i of props.roomData) {
                    if(i.roomIndex == props.keyRoom){
                        i.defects = props.defects
                    }
                }
            } else {
                Data.value.title = itemName.value
                Data.value.itemIndex = props.furnitures.length
                props.furnitures.splice(props.furnitures.length, 0, { ...Data.value });

                for(const i of props.roomData) {
                    if(i.roomIndex == props.keyRoom){
                        i.furnitures = props.furnitures
                    }
                }
            }

            emit('getData', props.roomData)
            emit('update:is-furniture-modal-active', false)
            itemName.value = []
        }

        const getFurnitureItems = async() => {
            await store
                .dispatch('app-furniture/getFurnitureItemData')
                .then((response) => {
                    furnitureItemData.value = response.data.data
                });
        }

        // const getInventoryData = async() => {
        //     await store
        //         .dispatch('app-furniture/getInventoryListData', {id: })
        //         .then((response) => {
        //             // furnitureItemData.value = response.data.data
        //         });
        // }

        const onSearch = (searchText) => {
            searchInput.value = searchText;
        }

        const onInput = (selectedItems) => {
            const lastInput = selectedItems[selectedItems.length - 1];

            if (
                lastInput &&
                typeof lastInput === "string" &&
                !furnitureItemData.value.some(
                    (option) => {
                        const existingData = option.name? option.name.toLowerCase(): ''
                        return existingData === lastInput.toLowerCase()
                    }
                )
            ) {
                const newOption = {
                    title: lastInput,
                };

                furnitureItemData.value.push(newOption.title);
                selectedItems[selectedItems.length - 1] = newOption.title;
            }

            itemName.value = selectedItems;
        }

        onMounted(() => {
            getFurnitureItems()
            // getInventoryData()
        })
    
        const {
            refFormObserver,
            getValidationState,
            resetForm,
        } = formValidation();

        return {
            refFormObserver,
            getValidationState,
            resetForm,
            roomType,
            getFurnitureItems,
            furnitureItemData,
            addItem,
            Data,
            itemName,
            searchInput,
            onSearch,
            onInput,
            // getInventoryData,
        };
    },
};
</script>
<style lang="scss">

@import "@core/scss/vue/libs/quill.scss";

.tag-input-container {
    max-width: 400px;
  }
  
  .selected-tags {
    display: flex;
    flex-wrap: wrap;
  }
  
  .dropdown-container {
    width: 100%;
  }

</style>    