<template>
    <b-modal
        :visible="isRoomModalActive"
        size="l"
        hide-footer
        no-close-on-backdrop
        @change="(val) => $emit('update:is-room-modal-active', val)"
    >
        <template #modal-title> Add Room</template>
        <b-container class="bv-example-row">
            <b-row class="justify-content-center">
                <b-col v-for="(room, index) in roomTypeList" :key="index" cols="12" sm="6" md="4" class="d-flex justify-content-center">
                    <button type="button" class="btn btn-outline-success btn-lg uniform-btn mt-2 mb-2" @click="selectedRoom(room.name)">
                        {{ room.name }}
                    </button>
                </b-col>
            </b-row>
        </b-container>
    </b-modal>
</template>
    
<script>
import {
    BCard, BCardText, BFormFile, BForm, BFormGroup, BFormInput, BRow, BCol, BButton,
    BImg, BModal, BFormInvalidFeedback, BTabs, BTab, BContainer,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import { ref, onMounted } from "@vue/composition-api";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import axios from "@axios";
import "quill/dist/quill.snow.css";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import RepeaterFurnitureForm from "./RepeaterFurnitureForm.vue";
    
export default {
    components: {
        BCard, BCardText, BFormFile, BTabs, BTab, BForm, BFormGroup, BFormInput, BRow, BCol,
        BButton, BImg, BFormInvalidFeedback, vSelect, BModal, ValidationProvider, ValidationObserver,
        BContainer, RepeaterFurnitureForm,
    },
    model: {
        prop: "isRoomModalActive",
        event: "update:is-room-modal-active",
    },
    props: {
        isRoomModalActive: {
            type: Boolean,
            required: true,
        },
    },
    directives: {
        Ripple,
    },
    methods: {
        selectedRoom(type) {
            this.roomType = type
            this.$emit('room-type', this.roomType)
            this.$emit('update:isRoomModalActive', false);
        }
    },
    setup() {
        const roomType = ref('')
        const roomTypeList = ref([])

        const getData = () => {
            store
                .dispatch("app-furniture/getRoomTypeData")
                .then((response) => {
                    roomTypeList.value = response.data.data
                }).catch((error) => {
                    this.makeToast('danger', 'Error 403', `${error?.response?.data?.message??'Something went wrong'}`)
                })
        }

        onMounted(() => {
            getData();
        });
    
        const {
            refFormObserver,
            getValidationState,
            resetForm,
        } = formValidation();

        return {
            refFormObserver,
            getValidationState,
            resetForm,
            roomType,
            roomTypeList,
        };
    },
};
</script>
<style lang="scss">

@import "@core/scss/vue/libs/quill.scss";
.uniform-btn {
    width: 100%;
    max-width: 200px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
}

</style>    